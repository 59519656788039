import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './reduce.scss'

export default () =>
    <StaticQuery
        query={graphql`
        query {
            Interface: file(relativePath: { eq: "industries/interface.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        `}
        render={data => (
            <section className="gamingVrPage__reduce">
                <div className="container">
                    <div className="row">
                        <div className="col__md-50">
                            <h2>Reduce Customer Support Overhead</h2>
                            <p>Many End-users change their hardware quite frequently, which increases the amount of support they require to get working licensing. With LicenseSpring, you can check on the status of any license key, and modify its entitlements.</p>
                            <p>
                            We also provide a self-serve customer service portal for your customers to reset their licenses in case they change machines.
                            </p>
                        </div>
                        <Img fluid={data.Interface.childImageSharp.fluid} alt="Interface image" className="col__md-50" />
                    </div>
                    
                    
                </div>
            </section>
        )}
    />