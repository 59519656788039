import React from 'react'
import SEO from "../../../components/Seo"
import Layout from '../../../components/Layout'
import TrustedBlock from '../../../components/TrustedBlock'
import BottomBlock from '../../../components/BottomBlock'

import Intro from '../../../components/Pages/Industries/GamingVr/Intro'
import Reduce from '../../../components/Pages/Industries/GamingVr/Reduce'
import Creative from '../../../components/Pages/Industries/GamingVr/Creative'
import Motto from '../../../components/Pages/Industries/GamingVr/Motto'

export default ({location}) => 
    <Layout className="page page--industry gamingVrPage">
          <SEO
                  title="LicenseSpring: Licensing for Gaming and VR Applications"
                  description="LicenseSpring integrates with Unity and with Unreal Engine, making it easy to add licensing in your gaming, CGI, or VR project. Learn more."
                  path={location.pathname}
            />
          <Intro />
          <Reduce />
          <Creative />
          <Motto />
          <TrustedBlock />
          <BottomBlock pricingLink/>
    </Layout>