import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../../../../Button"

import "./intro.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        HeroImage: file(relativePath: { eq: "industries/gamingvr.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="gamingVrPage__intro">
        <Img
          className="gamingVrPage__heroImage"
          fluid={data.HeroImage.childImageSharp.fluid}
          alt="Hero image"
        />
        <div className="container">
          <h1>
            {" "}
            Software Licensing for <span>Gaming and VR</span>
          </h1>
          <p>
            From 3D visualizations and interactive training applications to
            large-scale multiplayer VR games, LicenseSpring is a trusted partner
            for VR and game production studios to easily match the state of
            their works with that of their licensing agreements.
          </p>
          <ul>
            <li>
              Integrate using our SDKs or wrappers for Unreal Engine or Unity
            </li>
            <li>License content on Steam</li>
            <li>Easily manage licensing for DLC</li>
          </ul>
          <Button
            label="Open trial account"
            size="big"
            shadow
            color="blue"
            disclaimer
            to="/get-started/"
          />
        </div>
      </section>
    )}
  />
)
