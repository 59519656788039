import React from "react"

import ArrowLink from "../../../../ArrowLink"

import Steam from "../../../../../assets/img/industries/logo_steam.svg"
import Unity from "../../../../../assets/img/industries/logo_unity.svg"
import Unreal from "../../../../../assets/img/industries/logo_unreal_engine.svg"

import "./creative.scss"

export default () => (
  <section className="gamingVrPage__creative">
    <div className="container">
      <div className="row">
        <div className="col__md-50">
          <h2>Easily Add Licensing to your creative work</h2>
          <p>
            LS features easy-to-use plugins and SDKs for Unreal Engine and
            Unity. You can initialize and configure licensing in your project
            within a few clicks.
          </p>
          <p>
            Use our Steam integration to programmatically generate and assign
            licensing when an order comes in. You can also sell games directly
            on your website or any other E-commerce site.
          </p>
        </div>
        <div className="col__md-50">
          <div className="enginesLogos">
            <img src={Steam} alt="Steam logo" />
            <img src={Unity} alt="Unity logo" />
            <img src={Unreal} alt="Unreal Engine logo" />
          </div>
          <ArrowLink
            to="https://docs.licensespring.com/docs/unity"
            label="Unity docs"
          />
          {/* <ArrowLink to="/" label="UE Docs" />
                    <ArrowLink to="/" label="Steam Integration" /> */}
        </div>
      </div>
    </div>
  </section>
)
