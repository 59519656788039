import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './motto.scss'

export default () =>
    <StaticQuery
        query={graphql`
        query {
            Enversed: file(relativePath: { eq: "industries/enversed_logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        `}
        render={data => (
            <section className="gamingVrPage__motto">
                <div className="container">
                    <p>“LicenseSpring has been great to work with. They have been very responsive to requests and issues as we got started with them. And probably most importantly, their licensing service is very reliable.”</p>
                    <h3>Rutger Meijering
                    <span>VR Production & Project Manager | Enversed Studios</span></h3>
                    <Img fluid={data.Enversed.childImageSharp.fluid} alt="Enversed logo" />
                </div>
            </section>
        )
    } />